var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable max-len */
import { matchPath } from 'react-router-dom';
import moment from 'moment-timezone';
import { DATE_FORMAT, DEFAULT_CARE_AGENT_DATA, MONTHS, US_STATE_MAPPINGS, facilitySlugs, } from './global.constants';
import { validateDate } from './date.functions';
import { ROLES_CONFIG } from 'config/role.config';
import { CaregiverType, Roles } from './enums';
import crypto from 'crypto';
import CryptoJS from 'crypto-js';
/*
 *
 * @param {string} title will be change the document title
 */
export var changeDocumentTitle = function (title, isCustom) {
    if (isCustom && title) {
        document.title = title;
    }
    else if (title && !isCustom) {
        document.title = "".concat(title, " | ClearWellness");
    }
    else {
        document.title = "ClearWellness";
    }
};
export var emptyStringToNull = function (obj) {
    function eachRecursive(newObj) {
        for (var data in newObj) {
            if (typeof newObj[data] == 'object' && newObj[data] !== null)
                eachRecursive(newObj[data]);
            else if (newObj[data] === '' || newObj[data] === undefined) {
                newObj[data] = null;
            }
        }
        return newObj;
    }
    return eachRecursive(obj);
};
/**
 * @function isEmptyObject
 * @description check atleast one key has value in the given object
 * @param obj
 * @returns
 */
export var isEmptyObject = function (obj) {
    return Object.values(emptyStringToNull(obj)).some(function (item) {
        if (item && typeof item !== 'object') {
            return item;
        }
        else if (item && typeof item === 'object') {
            var innerObject = Object.values(item).some(function (item) { return item !== null; });
            return innerObject;
        }
    });
};
export var toTitleCase = function (str) {
    if (!str)
        return '';
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};
export var getTimestamp = function (str) {
    if (!str) {
        return;
    }
    var timestamp = str.toString().slice(0, -6);
    return parseInt(timestamp);
};
export var getCurrentResidentID = function () {
    var _a;
    var facilityManagement = facilitySlugs.facilityManagement, facilityHomePage = facilitySlugs.facilityHomePage, facilityResident = facilitySlugs.facilityResident, residentDashboard = facilitySlugs.residentDashboard, accountID = facilitySlugs.accountID, timezone = facilitySlugs.timezone;
    var params = (matchPath({
        path: "/".concat(facilityManagement, "/:").concat(facilityHomePage, "/:").concat(facilityResident, "/:").concat(residentDashboard, "/:").concat(accountID, "/:").concat(timezone, "/*"),
    }, window.location.pathname) || { params: {} }).params;
    return {
        seniorID: params[residentDashboard] || '',
        accountID: params[accountID] || '',
        timezone: (_a = params[timezone]) === null || _a === void 0 ? void 0 : _a.replace(/-/g, '/'),
        senior_id: params[residentDashboard] || '',
        account_id: params[accountID] || '',
    };
};
export var getExactDaysDifference = function (startTime, endTime) {
    var startTimeMs = startTime / 1000000;
    var endTimeMs = endTime / 1000000;
    var startDate = new Date(startTimeMs);
    var endDate = new Date(endTimeMs);
    var timeDifference = endDate.getTime() - startDate.getTime();
    var exactDaysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return Math.round(exactDaysDifference);
};
export var getCurrentSenior = function () {
    var _a;
    var residentDashboard = facilitySlugs.residentDashboard, accountID = facilitySlugs.accountID, timezone = facilitySlugs.timezone, senior = facilitySlugs.senior;
    var params = (matchPath({
        path: "/".concat(senior, "/:").concat(residentDashboard, "/:").concat(accountID, "/:").concat(timezone, "/*"),
    }, window.location.pathname) || { params: {} }).params;
    if (params[residentDashboard]) {
        return {
            seniorID: params[residentDashboard] || '',
            accountID: params[accountID] || '',
            timezone: (_a = params[timezone]) === null || _a === void 0 ? void 0 : _a.replace(/-/g, '/'),
            senior_id: params[residentDashboard] || '',
            account_id: params[accountID] || '',
        };
    }
    else {
        return getCurrentResidentID();
    }
};
export var getHourMin = function (time, currentUnit, format) {
    if (currentUnit === void 0) { currentUnit = 's'; }
    if (format === void 0) { format = 'long'; }
    var inHour = currentUnit == 's' ? time / 60 / 60 : time / 60;
    var inMin = currentUnit == 's' ? time / 60 : time;
    var durationHour = Math.floor(inHour);
    var durationMin = Math.floor(inMin) - durationHour * 60;
    if (durationHour) {
        return "".concat(durationHour).concat(format == 'short' ? 'h' : 'hrs', " ").concat(durationMin).concat(format == 'short' ? 'm' : 'min');
    }
    else {
        return "".concat(durationMin).concat(format == 'short' ? 'm' : 'min');
    }
};
export var getHourMinSec = function (time, currentUnit, format) {
    if (currentUnit === void 0) { currentUnit = 's'; }
    if (format === void 0) { format = 'long'; }
    var inSec = currentUnit === 's' ? time : time * 60; // Convert to seconds if input is in minutes
    var durationDay = Math.floor(inSec / (3600 * 24)); // Get full days
    var durationHour = Math.floor((inSec % (3600 * 24)) / 3600); // Get full hours
    var durationMin = Math.floor((inSec % 3600) / 60); // Get full minutes
    var durationSec = Math.floor(inSec % 60); // Get remaining seconds
    var timeString = '';
    // Add days if greater than 0
    if (durationDay > 0) {
        timeString += "".concat(durationDay).concat(format === 'short' ? 'd' : ' days', " ");
    }
    // Add hours if greater than 0 or if days exist (to show full time with hours)
    if (durationHour > 0 || durationDay > 0) {
        timeString += "".concat(durationHour).concat(format === 'short' ? 'h' : ' hrs', " ");
    }
    // Add minutes if greater than 0 or if hours or days exist (to show full time)
    if (durationMin > 0 || durationHour > 0 || durationDay > 0) {
        timeString += "".concat(durationMin).concat(format === 'short' ? 'm' : ' min', " ");
    }
    // Add seconds if greater than 0 or if it's the smallest unit (no hours or minutes)
    if (durationSec > 0 || (durationDay === 0 && durationHour === 0 && durationMin === 0)) {
        timeString += "".concat(durationSec).concat(format === 'short' ? 's' : ' sec');
    }
    return timeString.trim();
};
export var printStartEndTime = function (startTime, endTime) {
    // eslint-disable-next-line no-console
    console.info('StartTime', moment(getTimestamp(startTime)).format('llll'));
    // eslint-disable-next-line no-console
    console.info('EndTime', moment(getTimestamp(endTime)).format('llll'));
};
export var getCurrentDate = function (timezone) {
    var currentDate = moment().tz(timezone).format('L');
    var currentUTCOffset = moment().tz(timezone).format('Z');
    var currentStartTime = moment("".concat(currentDate, " 00:00:00").concat(currentUTCOffset)).format('x');
    var currentEndTime = moment("".concat(currentDate, " 23:59:59").concat(currentUTCOffset)).format('x');
    return {
        startTime: parseInt(currentStartTime) * 1000000,
        endTime: parseInt(currentEndTime) * 1000000,
    };
};
export var getCurrentSleepDate = function (timezone) {
    var _a, _b, _c, _d, _e, _f;
    var currentDate = (_a = moment().tz(timezone)) === null || _a === void 0 ? void 0 : _a.format('L');
    var currentUTCOffset = (_b = moment().tz(timezone)) === null || _b === void 0 ? void 0 : _b.format('Z');
    var currentStartTime = (_d = (_c = moment("".concat(currentDate, " 00:00:00").concat(currentUTCOffset))) === null || _c === void 0 ? void 0 : _c.subtract('04:00:00')) === null || _d === void 0 ? void 0 : _d.format('x');
    var currentEndTime = (_f = (_e = moment("".concat(currentDate, " 23:59:59").concat(currentUTCOffset))) === null || _e === void 0 ? void 0 : _e.subtract('03:00:00')) === null || _f === void 0 ? void 0 : _f.format('x');
    return {
        startTime: parseInt(currentStartTime) * 1000000,
        endTime: parseInt(currentEndTime) * 1000000,
    };
};
export var formatDuration = function (totalMinutes, daysDifference) {
    if (daysDifference === void 0) { daysDifference = 1; }
    var minutesPerDay = totalMinutes / daysDifference;
    var days = Math.floor(minutesPerDay / (60 * 24));
    var hours = Math.floor((minutesPerDay % (60 * 24)) / 60);
    var minutes = Math.floor(minutesPerDay % 60);
    var seconds = Math.round((minutesPerDay % 1) * 60);
    return [
        days ? "".concat(days, "d") : '',
        hours ? "".concat(hours, "h") : '',
        minutes ? "".concat(minutes, "m") : '',
        seconds ? "".concat(seconds, "s") : ''
    ].filter(Boolean).join('').trim() || '0s';
};
export var calculatePercentageGoals = function (actual, goal) {
    return Math.round((actual / goal) * 100);
};
export function getAge(dob) {
    if (!dob) {
        return "-";
    }
    var dobDate = new Date(dob); // Convert the DOB string to a Date object
    var today = new Date(); // Get today's date
    var age = today.getFullYear() - dobDate.getFullYear(); // Calculate the year difference
    var monthDifference = today.getMonth() - dobDate.getMonth();
    // Adjust if the birthday hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dobDate.getDate())) {
        age--;
    }
    return age;
}
export var getAgeFromTimestamp = function (timestamp) {
    if (!timestamp)
        return 0;
    var timestampDate = new Date(timestamp);
    var timeDifMs = Date.now() - timestampDate.getTime();
    return timeDifMs;
};
export var encrypt = function (text) {
    var secretKey = '36ffa5dea1875285f7dae9006f8a5d4e';
    var iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
    var encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Hex.parse(secretKey), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    var encryptedWithIV = iv.concat(encrypted.ciphertext);
    return CryptoJS.enc.Base64.stringify(encryptedWithIV); // Return as Base64
};
export var parseJwt = function (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window
        .atob(base64)
        .split('')
        .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(''));
    return JSON.parse(jsonPayload);
};
export var toIsoString = function (date) {
    var tzo = -date.getTimezoneOffset(), dif = tzo >= 0 ? '+' : '-', pad = function (num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    return (date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes()) +
        ':' +
        pad(date.getSeconds()) +
        dif +
        pad(tzo / 60) +
        ':' +
        pad(tzo % 60));
};
export var getAgencyName = function (agency) {
    if (agency === 'Community') {
        return 'Agency';
    }
    return agency;
};
export var saveUserInfo = function (info) {
    localStorage.setItem('userInfo', JSON.stringify(info));
};
/**
 * @description get parsed local storage value
 * @param {:- session storage key } key
 * @returns value || null
 */
export var getSessionStorage = function (key) {
    var cache = sessionStorage.getItem(key);
    return cache ? JSON.parse(cache) : null;
};
/**
 * @description set session storage
 * @param {*} key string
 * @param {*} value string
 */
export var setSessionStorage = function (key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
};
/**
 * @description remove item from session storage
 * @param key value to remove from session storage
 */
export var removeSessionStorage = function (key) {
    sessionStorage.removeItem(key);
};
/**
 * @description get parsed local storage value
 * @param {: - local storage key} key
 * @returns {DefaultCareAgentData} value || null
 */
export var getLocalStorage = function (key) {
    var cache = localStorage.getItem(key);
    return cache ? JSON.parse(cache) : null;
};
/**
 * @description set local storage
 * @param {*} key string
 * @param {*} value string
 */
export var setLocalStorage = function (key, value) {
    localStorage.setItem(key, JSON.stringify(value));
};
/**
 * @description remove item from local storage
 * @param key value to remove from local storage
 */
export var removeLocalStorage = function (key) {
    localStorage.removeItem(key);
};
/**
 * @description get care agent data from localstorage
 * @returns {any}
 */
export var getCareAgentInfo = function () {
    return getLocalStorage('userInfo') || DEFAULT_CARE_AGENT_DATA;
};
/**
 * @description check user session
 * @returns boolean
 */
export var checkUserSession = function () { return getLocalStorage('isUser'); };
/**
 * @description fn to inject the storage event
 * @param eventName event name that needs to inject
 */
export var injectStorageEvent = function (eventName, value) {
    setLocalStorage(eventName, value);
    removeLocalStorage(eventName);
};
/**
 * @description convert kg to lbs
 * @param value value that needs to be converted
 */
export var kgToLbs = function (value) {
    if (value) {
        return value * 2.2046;
    }
};
/**
 * @description round off the value
 * @param value value that needs to be rounded off
 * @param round upto how many decimal places needs to ne rounded
 */
export var roundOff = function (value, round) {
    if (round === void 0) { round = 1; }
    if (value != null) {
        return parseFloat(String(value)).toFixed(round);
    }
    return '-';
};
/**
 * @function convertKgToLbs
 * @description convert kg to lbs
 * @param value value that needs to be converted
 */
export var convertKgToLbs = function (value) {
    if (!value)
        return 0;
    return value * 2.2046;
};
/**
 * @function convertLbsToKg
 * @description convert lbs to kg
 * @param value that needs to be converted
 */
export var convertLbsToKg = function (value, isParse) {
    if (isParse === void 0) { isParse = true; }
    if (!value)
        return 0;
    return isParse ? parseInt(String(value / 2.2046)) : value / 2.2046;
};
/**
 * @function feetToCm
 * @description convert feet and inch value into centimeter
 * @param feet value in feet
 * @param inch value in inch
 * @return value in centimeter
 */
export var feetToCm = function (feet, inch) {
    if (!feet && !inch)
        return 0;
    return parseInt(String(numberRoundOff(feet * 30.48 + inch * 2.54)));
};
/**
 * @function numberRoundOff
 * @description method to round off the number
 * @param value value that needs to be rounded off
 * @param round upto how many decimal places needs to ne rounded
 * @return {number} rounded number
 */
export var numberRoundOff = function (value, round) {
    if (round === void 0) { round = 1; }
    if (!value) {
        return value;
    }
    return Number(parseFloat(String(value)).toFixed(round));
};
/**
 * @description remove new object with no data
 * @param bucket actual object
 * @param normalization normalized object
 */
export var removeActivityWithNoData = function (bucket, normalization) {
    for (var x in normalization) {
        if (bucket[parseInt(x)].length == 0) {
            delete bucket[x];
            delete normalization[x];
        }
    }
    return { bucket: bucket, activity: normalization };
};
/**
 * @description Normalize Activity graph
 * @param data actual data that need to be normalized
 * @param domainMax min range
 * @param domainMin max range
 */
export var normalizeActivity = function (data, domainMax, domainMin) {
    // domainMax: Min Heart Rate - 1
    // domainMin: Minimun of Y axis tick for overall graph  eg: (min * 0.8), 0 , (min * 0.5)
    var bucket = {};
    for (var x in data) {
        var diff = domainMax - domainMin;
        var mediumOffset = (data[x].medium_activity / 100) * diff;
        var highOffset = (data[x].high_activity / 100) * diff;
        if (data[x].high_activity !== 0) {
            if (data[x].medium_activity !== 0) {
                data[x].high_activity = highOffset;
            }
            else {
                data[x].high_activity = domainMin + highOffset;
            }
        }
        if (data[x].medium_activity !== 0) {
            data[x].medium_activity = domainMin + mediumOffset;
        }
        // Bucket
        var y = parseInt(x);
        bucket[y] = y + parseInt(data[x].activity_duration) * 1000000000;
    }
    return { bucket: bucket, normalizedActivity: data };
};
/**
 * @description putToBucket
 * @param bucket bucket list
 * @param measurement data points
 * @param key_name heart_rate/respiration
 */
export var putToBucket = function (bucket, measurement, key_name) {
    var bucket_assignment = {};
    var counter = 0;
    for (var start_key in bucket) {
        var start_point = parseInt(start_key);
        var end_point = parseInt(bucket[start_key]);
        for (var i = counter; i < measurement.length; ++i) {
            if (bucket_assignment[start_key] === undefined) {
                bucket_assignment[start_key] = [];
            }
            if (measurement[i].time >= start_point &&
                measurement[i].time <= end_point) {
                bucket_assignment[start_key].push(measurement[i][key_name]);
            }
            // else {
            //   break_statement = true;
            // }
        }
    }
    return bucket_assignment;
};
/**
 * @description capitalizeFirstLetter
 * @param string string that needs to be capitalize
 */
export var capitalizeFirstLetter = function (string, noDash) {
    if (noDash === void 0) { noDash = false; }
    if (!string) {
        if (noDash)
            return '';
        return '-';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
};
/**
 * @param {date} dt
 * @param {date-time} tm
 * @returns date time combined
 */
export var formatDateTime = function (dt, tm) {
    var newDt = moment(dt).format(DATE_FORMAT);
    var newTm = moment(tm).format('HH:mm');
    var dateTime = moment("".concat(newDt, " ").concat(newTm)).format();
    return dateTime.replace(/Z/, '');
};
export var maskPhoneNumber = function (number) {
    if (!number)
        return "";
    return "(".concat(number.substring(0, 3), ") ").concat(number.substring(3, 6), "-").concat(number.substring(6, number.length));
};
export var unmaskPhoneNumber = function (value) {
    if (value) {
        return value.replace(/\(|\)|-|\s*/g, '');
    }
    return value;
};
export var maskMAC = function (mac) {
    var MAC_LENGTH = 12;
    var HYPHEN_INDEXES = [1, 3, 5, 7, 9];
    var isLastChar = function (index, str) { return index === str.length - 1; };
    if (!mac)
        return '';
    var unmaskedMac = unmaskMAC(mac);
    return unmaskedMac
        .slice(0, MAC_LENGTH)
        .split('')
        .reduce(function (acc, digit, index) {
        var result = "".concat(acc).concat(digit);
        if (!isLastChar(index, mac)) {
            if (HYPHEN_INDEXES.includes(index))
                return "".concat(result, ":");
        }
        return result;
    }, '');
};
export var unmaskMAC = function (str) {
    return String(str).replace(/[^a-zA-Z0-9]/g, '');
};
export var roundToTen = function (n, range) {
    var a = parseInt(String(n / 10)) * 10;
    if (range == 'min') {
        return a;
    }
    else {
        return a + 10;
    }
};
export var updateBucketWithNoActivity = function (bucket, startT, endT) {
    var globalTime = 3600000000000;
    if (Object.keys(bucket).length === 0) {
        var tempTime = startT;
        var tempBucket = {};
        while (tempTime <= endT) {
            var tempStart = tempTime;
            tempTime += globalTime;
            tempBucket[tempStart] = tempTime;
        }
        return tempBucket;
    }
    else {
        var bucketKey = Number(Object.keys(bucket)[0]);
        var tempKey = bucketKey;
        while (startT + globalTime < tempKey) {
            var newEndTime = tempKey;
            tempKey -= globalTime;
            bucket[tempKey] = newEndTime;
        }
        tempKey = bucketKey;
        while (endT - globalTime > tempKey) {
            var newStartTime = tempKey;
            tempKey += globalTime;
            bucket[newStartTime] = tempKey;
        }
        return bucket;
    }
};
export var getQueryParamTimezone = function (timezone) {
    if (timezone === void 0) { timezone = ''; }
    return (timezone === null || timezone === void 0 ? void 0 : timezone.replace(/\//g, '-')) || moment.tz.guess().replace(/\//g, '-');
};
/**
 * @description sort by key
 * @param arr array that needs to be sorted
 * @param key key for which sorting needs to be done
 */
export var sortBy = function (arr, key) {
    return arr.sort(function (a, b) {
        // eslint-disable-next-line max-len
        var valueA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]; // ignore upper and lowercase
        // eslint-disable-next-line max-len
        var valueB = typeof a[key] === 'string' ? b[key].toUpperCase() : b[key]; // ignore upper and lowercase
        if (valueA < valueB) {
            return -1;
        }
        if (valueA > valueB) {
            return 1;
        }
        // names must be equal
        return 0;
    });
};
export var getAvgOfTwoValue = function (valueOne, valueTwo) {
    if (valueOne || valueTwo) {
        return (valueOne + valueTwo) / 2;
    }
    return 0;
};
/**
 * @description function to convert the array into object with specific key
 * @param {array} array
 * @param {string} key
 * @returns object
 */
export var convertArrayToObject = function (array, key) {
    return array.reduce(function (acc, curr) {
        acc[curr[key]] = curr;
        return acc;
    }, {});
};
/**
 * @function removeObjectProperty
 * @description fn to remove the property from an object without creating copy
 * @param {*} object
 * @param {string} property
 * @returns Object
 */
export var removeObjectProperty = function (obj, property) {
    return Object.keys(obj).reduce(function (prevValue, key) {
        if (key !== property) {
            prevValue[key] = obj[key];
        }
        return prevValue;
    }, {});
};
/**
 * @description fn to get existing vitals data
 * @param {string | any | undefined} timezone senior timezone
 * @returns vitals data
 */
export var getExistingVitalsData = function (timezone) {
    return {
        heart_rate_measurement: {
            label: 'Heart Rate',
            measurementUnit: 'Beats Per Minute (BPM)',
            measurementUnitAbbreviation: 'bpm',
            apiEndPoint: '/vitals/heart-rate-raw',
            downloadEndPoint: '/vitals/heart-summary-report',
            payload: {
                timezone: timezone,
            },
            reportPayload: {
                timezone: timezone,
            },
            isDisable: false,
        },
        body_weight: {
            label: 'Body Weight',
            measurementUnit: 'Pound Unit Of Mass (LBS)',
            measurementUnitAbbreviation: 'lbs',
            apiEndPoint: '/body-health/summary/body/insight',
            downloadEndPoint: '/body-health/body-summary-report',
            payload: {
                measurement_type: 'weight',
            },
            reportPayload: {
                timezone: timezone,
                measurement_type: 'weight',
            },
            isDisable: true,
        },
        body_hydration: {
            label: 'Body Hydration',
            measurementUnit: 'Percentage (%)',
            measurementUnitAbbreviation: '%',
            apiEndPoint: '/body-health/summary/body/insight',
            downloadEndPoint: '/body-health/body-summary-report',
            payload: {
                measurement_type: 'hydration',
            },
            reportPayload: {
                timezone: timezone,
                measurement_type: 'hydration',
            },
            isDisable: true,
        },
        activity_measurement: {
            label: 'Activity',
            measurementUnit: 'Minutes (Min)',
            measurementUnitAbbreviation: 'min',
            apiEndPoint: '/vitals/activity-series-insight',
            downloadEndPoint: '/vitals/activity-measurement-summary-report',
            payload: {
                timezone: timezone,
            },
            reportPayload: {
                timezone: timezone,
            },
            isDisable: true,
        },
        sleep_score: {
            label: 'Sleep Score',
            measurementUnit: 'Beats Per Minute (BPM)',
            measurementUnitAbbreviation: 'bpm',
            apiEndPoint: '/sleep/sleep-insight',
            downloadEndPoint: '/sleep/sleep-score-summary-report',
            reportPayload: {
                timezone: timezone,
            },
            isDisable: true,
        },
    };
};
/**
 * @description fn to validate the dates
 * @param {date} fromDate start date
 * @param {date} toDate end date date
 * @param {boolean | undefined} isDateError error value
 * @returns void
 */
export var searchRangeDateValidation = function (fromDate, toDate, isDateError) {
    var isError = false;
    if (!validateDate(fromDate) || !validateDate(toDate) || isDateError) {
        isError = true;
    }
    return isError;
};
/**
 * @function getCareAgentDetails
 * @description getCareAgentDetails
 */
export var getCareAgentDetailsParseJWT = function () {
    var userInfo = getCareAgentInfo();
    return parseJwt(userInfo.accessToken);
};
/**
 * @function showTextWithEllipsis
 * @description add ellipsis in string
 * @param {string} text
 * @param {number} limit
 * @returns {string} altered string
 */
export var showTextWithEllipsis = function (text, limit) {
    return text.length > limit ? "".concat(text.substring(0, limit), "...") : text;
};
/**
 * @function isAuthenticateUser
 * @description Method to return user is authenticated.
 * @returns {boolean}
 */
export var isAuthenticateUser = function () {
    var userInfo = getCareAgentInfo();
    return userInfo && userInfo.email ? true : false;
};
/**
 * @function isTwoArrayEqual
 * @description method to compare two array
 * @param {string[]} a
 * @param {string[]} b
 * @returns {boolean}
 */
export var isTwoArrayEqual = function (a, b) {
    return a.length === b.length && a.every(function (v, i) { return v === b[i]; });
};
/**
 * @function getCurrentUserRoleConfig
 * @description method to get role base configuration of the current portal user
 * @param {string[]} userRoleList
 */
export var getCurrentUserRoleConfig = function () {
    var loginUserDetails = getCareAgentInfo();
    var currentUserRole = loginUserDetails.userRole.find(function (role) { return ROLES_CONFIG[role]; });
    var roleConfig = JSON.parse(JSON.stringify(ROLES_CONFIG));
    //for FA add facility id in url
    if (currentUserRole === Roles.FACILITY_AGENT) {
        roleConfig[currentUserRole].defaultPage = "".concat(ROLES_CONFIG[currentUserRole].defaultPage, "/").concat(loginUserDetails.facility_info.facility_id);
    }
    if (currentUserRole) {
        return roleConfig[currentUserRole];
    }
    return null;
};
export var getAddressByLatLng = function (lat, lng) { return __awaiter(void 0, void 0, void 0, function () {
    var geocoder, response, addr;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (lat == null || lng == null) {
                    return [2 /*return*/, '-'];
                }
                geocoder = new google.maps.Geocoder();
                return [4 /*yield*/, geocoder.geocode({
                        location: {
                            lat: lat,
                            lng: lng,
                        },
                    })];
            case 1:
                response = _a.sent();
                if (response.results[0]) {
                    addr = response.results[0];
                    return [2 /*return*/, addr.formatted_address];
                }
                else {
                    return [2 /*return*/, ''];
                }
                return [2 /*return*/];
        }
    });
}); };
export var createDashedLine = function (data) {
    if (!data)
        return;
    var array = [];
    data.forEach(function (interup, index) {
        var lastIndex = data[index - 1];
        var currentIndex = data[index];
        var nextIndex = data[index + 1];
        if (interup.y == null) {
            if (index > 0 && (lastIndex === null || lastIndex === void 0 ? void 0 : lastIndex.y)) {
                array.push({
                    x: lastIndex === null || lastIndex === void 0 ? void 0 : lastIndex.x,
                    y: lastIndex === null || lastIndex === void 0 ? void 0 : lastIndex.y,
                });
            }
            if (nextIndex === null || nextIndex === void 0 ? void 0 : nextIndex.y) {
                array.push({
                    x: (nextIndex === null || nextIndex === void 0 ? void 0 : nextIndex.x) || (currentIndex === null || currentIndex === void 0 ? void 0 : currentIndex.x),
                    y: nextIndex === null || nextIndex === void 0 ? void 0 : nextIndex.y,
                });
                array.push({
                    x: nextIndex === null || nextIndex === void 0 ? void 0 : nextIndex.x,
                    y: null,
                });
            }
        }
    });
    return array;
};
/***
 * function to calculate percentile value of an array
 * arguments(array, percentileValue)
 */
export var calculatePercentile = function (arr, p) {
    if (arr.length === 0)
        return 0;
    if (p <= 0)
        return arr[0];
    if (p >= 1)
        return arr[arr.length - 1];
    var array = arr.sort(function (a, b) {
        return a - b;
    });
    var index = (arr.length - 1) * p, lower = Math.floor(index), upper = lower + 1, weight = index % 1;
    if (upper >= arr.length)
        return array[lower];
    return array[lower] * (1 - weight) + array[upper] * weight;
};
/**
 * @function constructName function to create a full name
 * @param {string} firstName
 * @param {string} middleName
 * @param {string} lastName
 * @returns {string} fullName
 */
export var constructName = function (firstName, middleName, lastName) {
    if (firstName === void 0) { firstName = ''; }
    if (middleName === void 0) { middleName = ''; }
    if (lastName === void 0) { lastName = ''; }
    var fullName = [firstName, middleName, lastName].filter(Boolean).join(' ');
    return fullName;
};
/**
 * @function constructNameInitials function to create nameInitials
 * @param {string} firstName
 * @param {string} lastName
 * @returns {string} initials
 */
export var constructNameInitials = function (firstName, lastName) {
    if (firstName === void 0) { firstName = ''; }
    if (lastName === void 0) { lastName = ''; }
    var initials = [firstName, lastName]
        .filter(Boolean)
        .map(function (val) { return val.charAt(0); })
        .join('');
    return initials.toUpperCase();
};
/**
 * @function getStateFromAbbr function to get State Name from State Abbreviation
 * @param {string} abbr
 * @returns {string} State Name
 */
export var getStateFromAbbr = function (abbr) {
    var _a;
    return (((_a = US_STATE_MAPPINGS.filter(function (state) { return state.abbreviation.toLowerCase() === abbr.toLowerCase(); })[0]) === null || _a === void 0 ? void 0 : _a.name) || '');
};
/**
 * @function getAbbrFromState function to get State Abbreviaton from State Name
 * @param {string} name
 * @returns {string} State Abbreviation
 */
export var getAbbrFromState = function (name) {
    var _a;
    return (((_a = US_STATE_MAPPINGS.filter(function (state) { return state.name.toLowerCase() === name.toLowerCase(); })[0]) === null || _a === void 0 ? void 0 : _a.abbreviation) || '');
};
/**
 * @function constructLocation function to create short location string
 * @param {Record<string, any>} location
 * @param {boolean} abbr Should State be Abbreviated?
 * @returns {string} location
 */
export var constructLocation = function (location, abbr) {
    if (location === void 0) { location = {}; }
    if (abbr === void 0) { abbr = false; }
    var state = location === null || location === void 0 ? void 0 : location.state;
    if (state && abbr) {
        state = getAbbrFromState(state);
    }
    var locationString = [location === null || location === void 0 ? void 0 : location.city, state].filter(Boolean).join(', ');
    return locationString;
};
/**
 * @function constructShortTimezone function to create short timezone string
 * @param {string} timezone
 * @returns {string} short timezone
 */
export var constructTimezoneAbbr = function (timezone) {
    if (timezone === void 0) { timezone = ''; }
    if (timezone) {
        return moment().tz(timezone).zoneAbbr();
    }
    return '';
};
export var searchDelay = function (fn) {
    var timer;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var context = this;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            timer = null;
            fn.apply(context, args);
        }, 500);
    };
};
/**
 * @function assessmentDailogMessage
 * @description function to get Dialog message for save, submit, and reset for any assessment
 * @param {string} assessment_name
 * @param {string} type (save,submit,reset)
 */
export var assessmentDailogMessage = function (assessmentName, action) {
    return "".concat(assessmentName, " has been ").concat(action, " successfully.");
};
/**
 * @function calculatePercentage
 * @param {number} score
 * @param {number} maximumScore
 * @returns percantage value
 */
export var calculatePercentage = function (score, maximumScore) {
    return roundOff((score / maximumScore) * 100, 0);
};
/**
 * @function getMinMaxAxis
 * @description method to get min and max domain of axis on the basis of data
 * @param {any[]} array
 * @param {string} type it can be either {min} or {max}
 */
export var getMinMaxAxis = function (array, type) {
    var minValue = Math.floor(Math.min.apply(Math, array));
    var maxValue = Math.ceil(Math.max.apply(Math, array));
    var num = (maxValue - minValue) / array.length;
    var step = 0;
    if (num % 1 === 0) {
        step = Math.ceil(num / 5) * 5;
    }
    else {
        step = Math.ceil(num);
    }
    if (type == 'min') {
        return minValue - roundToTen(step);
    }
    else if (type == 'max') {
        return maxValue + roundToTen(step);
    }
};
/**
 * @function sortCareGiverInfo
 * @description to sort caregivers on the basis of caregiver type
 * @param {ICaregiverListItems[]} caregivers
 * @returns
 */
export var sortCareGiverInfo = function (caregivers) {
    if (caregivers.length > 0) {
        return caregivers.sort(function (a, b) {
            if ((a.caregiverType == CaregiverType.PRIMARY &&
                b.caregiverType !== CaregiverType.PRIMARY) ||
                (a.caregiverType == CaregiverType.SECONDARY &&
                    b.caregiverType == CaregiverType.ALTERNATE)) {
                return -1;
            }
            if ((a.caregiverType != CaregiverType.PRIMARY &&
                b.caregiverType == CaregiverType.PRIMARY) ||
                (a.caregiverType != CaregiverType.ALTERNATE &&
                    b.caregiverType == CaregiverType.SECONDARY)) {
                return 1;
            }
            return 0;
        });
    }
    return caregivers;
};
/**
 * @function getFormData
 * @description to get formData from object
 * @param {object} obj
 * @returns
 */
export var getFormData = function (obj) {
    var newFormData = Object.keys(obj).reduce(function (formData, key) {
        formData.append(key, obj[key]);
        return formData;
    }, new FormData());
    return newFormData;
};
/**
 * @function getLabelStepsValue
 * @description to get array of values with equal interval
 * @param {number} step
 * @param {number} maximumValue
 * @returns
 */
export var getLabelStepsValue = function (step, maximumValue) {
    var stepsArray = [];
    var i = 0;
    while (i <= maximumValue) {
        if (i % step == 0) {
            stepsArray.push({ value: i, label: "".concat(i) });
        }
        i++;
    }
    return stepsArray;
};
export var getMBValueInBytes = function (valueInMB) { return 1024 * 1024 * valueInMB; };
export var generateUniqueId = function (bytes) {
    if (bytes === void 0) { bytes = 16; }
    return crypto.randomBytes(bytes).toString('hex');
};
export var convertSecondsToMilliSeconds = function (valueInSeconds) {
    return 1000 * valueInSeconds;
};
/**
 * @description trimValuesInObject method trim values inside an object
 * @function trimValuesInObject
 * @param {any} data
 * @returns trimmed the leading and trailing spaces from the data object
 */
var trimValuesInObject = function (data) {
    for (var property in data) {
        data[property] = trimValues(data[property]);
    }
    return data;
};
/**
 * @description trimValuesInArray method trim values inside an array
 * @function trimValuesInArray
 * @param {any} data
 * @returns trimmed the leading and trailing spaces from the data array
 */
var trimValuesInArray = function (data) {
    return data.map(function (item) { return trimValues(item); });
};
/**
 * @description trimValues method trim all the values doesn't matter on the type of data
 * @function trimValues
 * @param {any} data
 * @returns trimmed the leading and trailing spaces from the data
 */
export var trimValues = function (data) {
    if (Array.isArray(data)) {
        return trimValuesInArray(data);
    }
    else if (typeof data === 'object') {
        return trimValuesInObject(data);
    }
    else if (typeof data === 'string') {
        return data.trim();
    }
    return data;
};
/**
 * @description method takes array of strings and convert them into a single string seperated with provided separator
 * @function capitalizeAndJoinStrings
 * @param {any} arrayOfString
 * @returns converts array of strings into single string seperated with provided separator
 */
export function capitalizeAndJoinStrings(arrayOfString, separator) {
    if (arrayOfString.length === 0) {
        return '';
    }
    return arrayOfString
        .map(function (str) { return str.charAt(0).toUpperCase() + str.slice(1); })
        .join(separator);
}
/**
 * @description gets count string for givemn count & string
 * @function getCountString
 * @param {number} count
 * @param {string} str
 * @returns Count + Plural or Singular String
 */
export var getCountString = function (count, str) {
    if (count === void 0) { count = 0; }
    if (str === void 0) { str = 'Item'; }
    return "".concat(count, " ").concat(str).concat(count === 1 ? '' : 's');
};
/**
 * @description converts an array into array of smaller arrays
 * @function getChunks
 * @param {T[]} list array of type T
 * @param {number} size size of each smaller array/chunk
 * @returns array of arrays
 */
export function getChunks(list, size) {
    if (list === void 0) { list = []; }
    if (size === void 0) { size = 10; }
    var remainingList = __spreadArray([], list, true);
    var chunks = [];
    while (remainingList.length) {
        chunks.push(remainingList.slice(0, size));
        remainingList = remainingList.slice(size);
    }
    return chunks;
}
/**
 * @description escape/sanitize a string for use as a RegExp
 * @function escapeStringRegexp
 * @param {string} str string to be escaped
 * @returns {string} escapedString
 */
export function escapeStringRegexp(str) {
    return str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
}
export var getSeniorResidentBaseUrl = function () {
    var _a = getCurrentSenior(), accountID = _a.accountID, seniorID = _a.seniorID, timezone = _a.timezone;
    var seniorUrlWithTab = function (endpoint, tab) {
        return "/senior/".concat(seniorID, "/").concat(accountID, "/").concat(getQueryParamTimezone(timezone), "/").concat(endpoint, "?tab=").concat(tab);
    };
    return { seniorUrlWithTab: seniorUrlWithTab };
};
/**
 * @description show empty - or emptyValue is value in not present
 * @function isValueAvailable
 * @param {any} value use to check is value present or not
 * @param {string} showValue use to render custom value
 * @param {string} postfix
 * @param {string} emptyValue
 * @returns {string} value or emptyValue
 */
export var isValueAvailable = function (_a) {
    var value = _a.value, showValue = _a.showValue, _b = _a.postfix, postfix = _b === void 0 ? '' : _b, _c = _a.emptyValue, emptyValue = _c === void 0 ? '-' : _c;
    return value ? "".concat(showValue !== null && showValue !== void 0 ? showValue : value, " ").concat(postfix) : emptyValue;
};
/**
 * @description get a list of months from range 1 to 12
 * @function getMonthsFromRange
 * @param {IMonth} start starting Month
 * @param {IMonth} end ending Month
 * @returns a list of months | maximum 12 Month range possible
 */
export var getMonthsFromRange = function (start, end) {
    if (start <= end) {
        return MONTHS.slice(start - 1, end);
    }
    else {
        return __spreadArray(__spreadArray([], MONTHS.slice(start - 1, 12), true), MONTHS.slice(0, end), true);
    }
};
/**
 * @description get a range of months from range 1 to 12
 * @function getMonthRange
 * @param {IMonth} months Number of Months in Range
 * @returns {IMonth[]} [startMonth, currentMonth] | maximum 12 Month range possible
 */
export var getMonthRange = function (months) {
    var currentMonth = new Date().getMonth() + 1;
    var diff = months - 1;
    var startMonth = 1;
    if (currentMonth <= diff) {
        startMonth = 12 - (diff - currentMonth);
    }
    else {
        startMonth = currentMonth - diff;
    }
    return [startMonth, currentMonth];
};
export var getCompareDataMinimal = function (minimalInfo) {
    var name = minimalInfo.name, mobile_number = minimalInfo.mobile_number, email = minimalInfo.email, gender = minimalInfo.gender, dob = minimalInfo.dob, address = minimalInfo.address, member_type = minimalInfo.member_type, _a = minimalInfo.facility_type, facility_type = _a === void 0 ? '' : _a, corporate = minimalInfo.corporate, facility = minimalInfo.facility, isResident = minimalInfo.isResident;
    var first_name = name.first_name, middle_name = name.middle_name, last_name = name.last_name;
    var street = address.street, _b = address.apartment_number, apartment_number = _b === void 0 ? '' : _b, city = address.city, zipcode = address.zipcode, state = address.state;
    var localDOB = dob ? moment.utc(dob).local().format(DATE_FORMAT) : '';
    var compareData = {
        name: {
            first_name: first_name,
            middle_name: middle_name,
            last_name: last_name,
        },
        mobile_number: maskPhoneNumber(mobile_number),
        email: email,
        gender: gender,
        dob: localDOB,
        address: {
            street: street,
            apartment_number: apartment_number,
            city: city,
            zipcode: zipcode,
            state: state,
        },
        member_type: member_type,
        facility_type: facility_type,
        isResident: isResident || false,
        corporate: corporate || 'Select Corporation',
        facility: facility || 'Select Agency',
    };
    return compareData;
};
export var isDateWithin90Days = function (historyTableData) {
    var data = historyTableData.some(function (item) {
        var itemDate = new Date(item.date);
        var ninetyDaysAgo = new Date();
        ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
        return itemDate >= ninetyDaysAgo;
    });
    return data;
};
export var filterCareAgentDataByName = function (careAgentData, searchQuery) {
    return careAgentData.filter(function (data) {
        var _a = data.name, first_name = _a.first_name, middle_name = _a.middle_name, last_name = _a.last_name;
        var namesArray = generateNameCombinations(first_name, middle_name, last_name);
        return namesArray.some(function (name) { return name.toLowerCase().includes(searchQuery.toLowerCase()); });
    });
};
export var filterSeniorDataByName = function (seniorsData, searchQuery) {
    return seniorsData.filter(function (data) {
        var _a = data.minimal.name, first_name = _a.first_name, middle_name = _a.middle_name, last_name = _a.last_name;
        var namesArray = generateNameCombinations(first_name, middle_name, last_name);
        return namesArray.some(function (name) { return name.toLowerCase().includes(searchQuery.toLowerCase()); });
    });
};
function generateNameCombinations(first, middle, last) {
    var namesArray = new Set();
    if (middle) {
        // Add the six specific combinations when the middle name is provided
        namesArray.add("".concat(first, " ").concat(middle, " ").concat(last));
        namesArray.add("".concat(first, " ").concat(last, " ").concat(middle));
        namesArray.add("".concat(middle, " ").concat(last, " ").concat(first));
        namesArray.add("".concat(middle, " ").concat(first, " ").concat(last));
        namesArray.add("".concat(last, " ").concat(first, " ").concat(middle));
        namesArray.add("".concat(last, " ").concat(middle, " ").concat(first));
    }
    else {
        // Add only two combinations when the middle name is not provided
        namesArray.add("".concat(first, " ").concat(last));
        namesArray.add("".concat(last, " ").concat(first));
    }
    return Array.from(namesArray); // Convert Set to Array
}
